<template>
  <div>
    <div v-if="this.routeParents[this.$route.name]">
      <List :isFiliation="isFiliation" />
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import List from "@/views/patients/List.vue";
import routeParents from "@/constants/dynamicMenu";

export default {
  name: "Main",
  data() {
    return {
      routeParents,
    };
  },
  computed: {
    isFiliation() {
      return this.$route.name === "filiations";
    },
  },
  components: {
    List,
  },
};
</script>
