import * as options from "./options";

const routeParents = {
  patients: {
    childrens: ["patients.detail"],
    menuTemplate: options.patients,
  },
  filiations: {},
};

export default routeParents;
